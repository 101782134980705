import React from "react";
import { careCoordinatorEmail } from "../../constants";
import { useDaybreakTranslation } from "../../hooks/useDaybreakTranslation";
import en from "./ParentWelcomePacket.en.json";
import es from "./ParentWelcomePacket.es.json";

export type CombinedLegalTextProps = { schoolName: string };
const CombinedLegalText: React.FC<CombinedLegalTextProps> = ({
  schoolName,
}) => {
  const { t } = useDaybreakTranslation("pwpForm", {
    en,
    es,
  });
  // Note: when someome gives you an updated legal document for here, the general process is:
  // 1. Export it from google doc as plain text
  // 2. Open it in a text editor and condense anywhere where there are more than 2-3 line breaks in a row.
  // 3. Find-and-replace all \n with \\n to escape newlines
  // 4. Replace all " with \" to escape double quotes
  // 5. Replace all \t with \\t to escape tabs
  // 6. Copy and paste it into the appropriate language json file.
  // From https://docs.google.com/document/d/1tUd9dNV2LzTpyOuPmCdCsjFVxoSL25funCZhoiuH73I/edit
  return <>{t("legalText", { schoolName, careCoordinatorEmail })}</>;
};

export default CombinedLegalText;
