import { SortingState } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useApi } from "../../../api/apiContext";
import { OrganizationMemberV1 } from "../../../api/queries/organizationMembers";
import { themeMinWidth } from "../../../app/theme";
import SchoolCounselorLayout from "../../../layouts/SchoolCounselorLayout/SchoolCounselorLayout";
import ReferredStudentsListTable from "./ReferredStudentsListTable";
import { trackPage } from "client/amplitudeHelper";

const Container = styled.div`
  width: 100%;
  padding: 0 0 80px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
const TableContainer = styled.div`
  ${themeMinWidth("small")} {
    padding-left: 32px;
    padding-right: 32px;
  }
`;
const Header = styled.div`
  font-weight: 600;
  font-size: 20px;
  margin-left: 24px;
  margin-top: 36px;
  margin-bottom: 40px;
`;
export type ReferredStudentsListPageWithDataProps = {
  organizationMembers: OrganizationMemberV1[];
  pageIndex: number;
  maxPageIndex: number;
  setPageIndex: (pageIndex: number) => void;
  setPageSort: (sort: SortingState[0]) => void;
};
export const ReferredStudentsListPageWithData: React.FC<ReferredStudentsListPageWithDataProps> = ({
  organizationMembers,
  setPageIndex,
  setPageSort,
  pageIndex,
  maxPageIndex,
}) => {
  return (
    <SchoolCounselorLayout>
      <Container>
        <Header>Referred Students</Header>
        <TableContainer>
          <ReferredStudentsListTable
            organizationMembers={organizationMembers}
            setPageIndex={setPageIndex}
            setPageSort={setPageSort}
            pageIndex={pageIndex}
            maxPageIndex={maxPageIndex}
          />
        </TableContainer>
      </Container>
    </SchoolCounselorLayout>
  );
};

const defaultSort = { id: "first_name", desc: false };
const sortToOrderParam = (sort: SortingState[0] | undefined) => {
  if (!sort) sort = defaultSort;
  return sort.id + "_" + (sort.desc ? "desc" : "asc");
};
export type ReferredStudentsListPageProps = {};
const ReferredStudentsListPage: React.FC<ReferredStudentsListPageProps> = () => {
  useEffect(() => {
    trackPage("SCD:ReferredStudentListPage");
  }, []);

  const api = useApi();
  const [pageSort, setPageSort] = useState<SortingState[0]>(defaultSort);
  const [pageIndex, setPageIndex] = useState(0);

  const {
    data: { data: organizationMembers, meta: metaData } = {},
    isLoading,
  } = api.useGetOrganizationMembers({
    page: pageIndex + 1,
    perPage: 10,
    referral_status: ["referral_completed"],
    fields: { organization_members: ["next_session_datetime"] },
    orderBy: sortToOrderParam(pageSort),
    options: { useErrorBoundary: true, keepPreviousData: true },
  });

  if (isLoading || !organizationMembers || !metaData) {
    return <SchoolCounselorLayout loading />;
  }
  return (
    <ReferredStudentsListPageWithData
      organizationMembers={organizationMembers}
      pageIndex={pageIndex}
      maxPageIndex={metaData.maxPage - 1}
      setPageIndex={setPageIndex}
      setPageSort={setPageSort}
    />
  );
};

export default ReferredStudentsListPage;
