import {
  FormGroup,
  FormRow,
  FormRowGrid,
  H1,
  H3,
  InsuranceSection,
  SubmitButtonSectionV2,
  Wrap,
} from "../elements";
import { LooksLikeLink } from "./elements";
import { Trans } from "react-i18next";
import TextLink from "../../../components/TextLink/TextLink";
import React, { useState } from "react";
import TealButton from "../../../components/buttons/TealButton/TealButton";
import Loading from "../../../components/Loading/Loading";
import { Insurance } from "../../../api/queries/insurance";
import styled from "styled-components";
import Icon from "../../../components/Icon/Icon";
import { useContactCard } from "../../../components/Forms/ContactCard/ContactCard";
import * as Yup from "yup";
import { Formik } from "formik";
import ErrorsSummary from "../../../components/Forms/ErrorsSummary/ErrorsSummary";
import { useModal } from "../../../components/Modals/BaseModal/BaseModal";
import NoInsurance from "./noInsurance";
import {
  StandardDateField,
  StandardTextField,
  StandardUploadField,
} from "../../../components/Forms/Field/StandardField";
import { trackEvent } from "client/amplitudeHelper";

export type SubmitInsuranceProps = {
  disabled?: boolean;
  t: (_: string) => string;
  createInsurance: (data: Insurance) => void;
  isLoading: boolean;
};

const Callout = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Teal-95, #def4fe);
`;

const Columns = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const ModeSwitch = styled.div`
  margin-bottom: 30px;
`;

const variableValidationSchema = (mode: "upload" | "manual" | "none") => {
  if (mode === "upload") {
    return {
      cardFront: Yup.string().required("Card Front is Required"),
      cardBack: Yup.string().required("Card Back is Required"),
      insuranceCompanyName: Yup.string(),
      memberId: Yup.string(),
      groupId: Yup.string(),
      planHolderDateOfBirth: Yup.string(),
    };
  } else if (mode === "manual") {
    return {
      cardFront: Yup.string(),
      cardBack: Yup.string(),
      insuranceCompanyName: Yup.string().required(
        "Insurance Company Name Required"
      ),
      memberId: Yup.string()
        .required()
        .matches(
          /^[-0-9a-zA-Z]+$/,
          "Member ID must be numbers, letters and dash (-) only"
        ),
      groupId: Yup.string()
        .nullable()
        .matches(
          /^[-0-9a-zA-Z]+$/,
          "Member ID must be numbers, letters and dash (-) only"
        ),
      planHolderDateOfBirth: Yup.date().required("Date of Birth Required"),
    };
  }
};
const initialValues: Insurance = {
  clientSfid: "",
  clientReferralSfid: "",
  insuranceCompanyName: "",
  memberId: "",
  groupId: "",
  cardFront: "",
  cardBack: "",
  insuranceStatusFromReferralForm: "Received",
};

const SubmitInsurance = ({
  disabled,
  t,
  isLoading,
  createInsurance,
}: SubmitInsuranceProps): JSX.Element => {
  const [mode, setMode] = useState<"upload" | "manual">("upload");

  const useNoInsuranceModal = useModal({
    onOpen: () => trackEvent("PWP:openedNoInsuranceModal"),
    onClose: () => trackEvent("PWP:closedNoInsuranceModal"),
  });

  const {
    ContactCard: PlanHolderContactCard,
    validationSchema: planHolderValidationSchema,
    initialValues: planHolderInitialValues,
  } = useContactCard({
    prefix: "planHolder",
    tracking: "PWP:Insurance",
    email: false,
    phone: false,
    address: true,
  });

  const validationSchema = Yup.object().shape({
    ...variableValidationSchema(mode),
    ...planHolderValidationSchema,
  });

  return (
    <>
      <InsuranceSection>
        <H1>{t("Submit Insurance Information")}</H1>
        <Callout>{t("insuranceProgramCost")}</Callout>

        <H3>{t("Why is Daybreak asking for my insurance information?")}</H3>
        <Columns>
          <Column>
            <Icon name="human" />
            <h5>{t("Free mental health support")}</h5>
            <span>{t("explainFree")}</span>
          </Column>
          <Column>
            <Icon name="sheildAlert" />
            <h5>{t("Confidential Healthcare Service")}</h5>
            <span>{t("explainConfidential")}</span>
          </Column>
          <Column>
            <Icon name="lockedLock" />
            <h5>{t("Secure Information Storage")}</h5>
            <span>{t("explainSecure")}</span>
          </Column>
        </Columns>

        <H3>{t("noInsuranceTitle")}</H3>
        <Wrap>
          <Trans
            components={{
              textlink: <TextLink href="https://www.daybreakhealth.com/faq" />,
            }}
          >
            {t("noInsuranceDescription")}
          </Trans>
        </Wrap>

        {isLoading && <Loading size="small" />}
        {!isLoading && (
          <>
            <H3>{t("insuranceFormSectionTitle")}</H3>
            <ModeSwitch>
              {mode === "manual" && (
                <>
                  {t("Prefer uploading photos?")}{" "}
                  <LooksLikeLink
                    style={{ display: "inline" }}
                    onClick={(e) => {
                      setMode("upload");
                      trackEvent(`PWP:Insurance:${e.type}:ModeSelect`, {
                        value: "upload",
                      });
                    }}
                  >
                    {t("Upload photos of insurance card")}
                  </LooksLikeLink>
                </>
              )}

              {mode === "upload" && (
                <>
                  {t("Don't have your insurance card?")}{" "}
                  <LooksLikeLink
                    style={{ display: "inline" }}
                    onClick={(e) => {
                      setMode("manual");
                      trackEvent(`PWP:Insurance:${e.type}:ModeSelect`, {
                        value: "manual",
                      });
                    }}
                  >
                    {t("Enter details manually")}
                  </LooksLikeLink>
                </>
              )}
            </ModeSwitch>

            <Formik
              initialValues={{ ...initialValues, ...planHolderInitialValues }}
              validationSchema={validationSchema}
              onSubmit={createInsurance}
            >
              {({ isSubmitting, handleSubmit, dirty }) => {
                return (
                  <>
                    {useNoInsuranceModal.asModal(
                      <NoInsurance
                        t={t}
                        onConfirm={() => {
                          trackEvent("PWP:Insurance:NoInsurance:POST");
                          createInsurance({
                            ...initialValues,
                            insuranceStatusFromReferralForm: "No Insurance",
                          });
                        }}
                        onCancel={useNoInsuranceModal.closeModal}
                      />
                    )}
                    <FormGroup>
                      {mode === "manual" && (
                        <>
                          <FormRowGrid>
                            <StandardTextField
                              label={t("Insurance Provider Company Name")}
                              name="insuranceCompanyName"
                              tracking="PWP:Insurance"
                            />
                            <StandardTextField
                              label={t("Member ID Number")}
                              name="memberId"
                              tracking="PWP:Insurance"
                            />
                            <StandardTextField
                              label={t("Group Number (if applicable)")}
                              name="groupId"
                              tracking="PWP:Insurance"
                            />
                            <StandardDateField
                              label={t("Policy Holder Date of Birth")}
                              name="planHolderDateOfBirth"
                              tracking="PWP:Insurance"
                            />
                          </FormRowGrid>
                        </>
                      )}

                      {/* Preserves the hidden inputs so if you return to this mode your file is still provided */}
                      <FormRow
                        style={{
                          display: mode === "upload" ? "flex" : "none",
                        }}
                      >
                        <StandardUploadField
                          label={t("Front of Insurance Card")}
                          name="cardFront"
                          accept="image/*, .pdf"
                          tracking="PWP:Insurance"
                        />
                        <StandardUploadField
                          label={t("Back of Insurance Card")}
                          name="cardBack"
                          accept="image/*, .pdf"
                          tracking="PWP:Insurance"
                        />
                      </FormRow>

                      <PlanHolderContactCard />

                      <ErrorsSummary />

                      <SubmitButtonSectionV2>
                        {isSubmitting ? (
                          <Loading size="small" />
                        ) : (
                          <TealButton
                            type="submit"
                            disabled={isSubmitting || disabled}
                            onClick={handleSubmit}
                          >
                            {t("Save")}
                          </TealButton>
                        )}
                      </SubmitButtonSectionV2>
                    </FormGroup>
                    <LooksLikeLink
                      onClick={() => !dirty && useNoInsuranceModal.openModal()}
                      disabled={dirty}
                    >
                      {t("My child does not have insurance")}
                    </LooksLikeLink>
                  </>
                );
              }}
            </Formik>
          </>
        )}
      </InsuranceSection>
    </>
  );
};

export default SubmitInsurance;
