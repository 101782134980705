import styled from "styled-components/macro";
import { themeColor, themeMinWidth } from "../../app/theme";

export const Container = styled.div`
  // prevent margin collapse
  overflow: auto;
  background-color: ${themeColor("teal2")};
  width: 100%;
  ${themeMinWidth("small")} {
    width: 1032px;
  }
`;
export const sidePaddingDesktop = 88;
export const sidePaddingMobile = 24;
export const sectionGap = 32;
export const HeaderSection = styled.div`
  position: relative;
  :not(:first-child) {
    margin-top: 64px;
  }
  padding: 0 ${sidePaddingMobile}px;
  ${themeMinWidth("small")} {
    padding: 0 ${sidePaddingDesktop}px;
  }
`;
export const LanguageSwitchButton = styled.div`
  margin-bottom: 16px;
  ${themeMinWidth("small")} {
    position: absolute;
    top: 0;
    right: 8px;
  }
`;

export const H1 = styled.h1`
  font-weight: 500;
  line-height: 52px;
  color: black;
  font-size: 32px;
  ${themeMinWidth("small")} {
    font-size: 45px;
  }
`;
export const H2 = styled.h2`
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: black;
  margin-top: 0;
  margin-bottom: 32px;
`;
export const H3 = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 16px;
  :not(:first-child) {
    margin-top: 16px;
  }
`;
export const Description = styled.div`
  line-height: 20px;
  letter-spacing: 0.25px;
  font-size: 14px;
`;

export const FormSection = styled.div`
  border-radius: 16px;
  background-color: white;
  margin-top: ${sectionGap}px;
  padding: 16px calc(${sidePaddingMobile}px - 8px);
  margin-left: 8px;
  margin-right: 8px;
  ${themeMinWidth("small")} {
    padding: 48px ${sidePaddingDesktop}px;
  }
`;
export const PatientInfoSection = styled(FormSection)`
  margin-top: 32px;
`;
export const ParentInfoSection = styled(FormSection)``;
export const LegalSection = styled(FormSection)``;
export const LegalText = styled.pre`
  text-wrap: wrap;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #41484b;
  height: 368px;
  overflow-y: scroll;
  font-size: 12px;
  ${themeMinWidth("small")} {
    font-size: 16px;
  }
`;
export const SignatureCheckboxes = styled.div`
  margin-top: 24px;
  font-size; 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const SignatureDescription = styled.div`
  margin-top: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
`;
export const SignatureTextbox = styled.div`
  margin-top: 16px;
  max-width: 416px;
`;
export const InsuranceButtonSection = styled.div`
  display: flex;
  justify-content: center;
`;
export const ScheduleSection = styled(FormSection)``;
export const ScheduleButtonSection = styled.div`
  display: flex;
  justify-content: center;
`;
export const SubmitButtonSection = styled.div`
  margin-top: 64px;
  margin-bottom: 128px;

  display: flex;
  justify-content: center;
  button {
    display: flex;
    flex-grow: 1;
    max-width: 200px;
  }
  ${themeMinWidth("small")} {
    width: 120px;
    // height: 48px;
    justify-content: flex-start;
    button {
      flex-grow: 0;
    }
  }
`;
export const SubmitButtonSectionV2 = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;

  display: flex;
  justify-content: center;
  button {
    display: flex;
    flex-grow: 1;
    max-width: 200px;
  }
  ${themeMinWidth("small")} {
    width: 120px;
    // height: 48px;
    justify-content: flex-start;
    button {
      flex-grow: 0;
    }
  }
`;
export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
export const FormRowGrid = styled(FormRow)`
  display: grid;
  grid-template-columns: 1fr;
  min-height: 54px;
  ${themeMinWidth("small")} {
    grid-template-columns: 1fr 1fr;
  }
`;
export const InsuranceSection = styled(FormSection)``;
// This wraps text that may translate into
// paragraphs leading to browser errors
// about nested P, so needs to be a div.
export const Wrap = styled.div``;

export const ErrorSection = styled(FormSection)``;
export const Errors = styled.div`
  color: ${themeColor("red")};
`;
export const PleaseConsent = styled.div`
  text-align: center;
  font-style: italic;
  margin-top: 10px;
`;
